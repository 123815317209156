import { ElementType, ReactNode } from 'react';
import clsx from 'clsx';

interface Props {
  id?: string;
  className?: string;
  variant: 'heading' | 'heading1' | 'subheading' | 'body1' | 'body2' | 'body3' | 'body4' | 'footer1' | 'footer2' | 'footer3' | 'footer4';
  component: string;
  children: ReactNode;
}

function Typography(props: Props) {
  const { id, className, variant = 'body', component = 'div', children, ...restProps } = props;

  const Component = component as ElementType;

  return (
    <Component
      id={id}
      className={clsx(
        variant === 'heading' && 'leading-12 text-28-35 font-normal',
        variant === 'heading1' && 'text-28-35 font-normal leading-5',
        variant === 'subheading' && 'text-16-24 font-normal',
        variant === 'body1' && 'text-14-21 font-normal',
        variant === 'body2' && 'text-14-21 font-medium',
        variant === 'body3' && 'text-12-14 font-normal',
        variant === 'body4' && 'text-20-25 font-normal',
        variant === 'footer1' && 'text-14-17 font-normal',
        variant === 'footer2' && 'text-14-17 font-bold',
        variant === 'footer3' && 'text-14-19 font-bold',
        variant === 'footer4' && 'text-12-12 font-medium',
        'text-[#141413]',
        className
      )}
      {...restProps}
    >
      {children}
    </Component>
  );
}

export { Typography };
