import IconProps from './IconProps';

function Reports(props: IconProps) {
  const { width = 24, height = 26, fill = '#96918B', ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.975342H1.28571C0.575634 0.975342 0 1.5723 0 2.30868V23.642C0 24.3784 0.575634 24.9753 1.28571 24.9753H18.7143C19.4244 24.9753 20 24.3784 20 23.642V4.97534L16 0.975342ZM14 2.97534L18 6.97534H14V2.97534ZM18 22.9753H2V2.97534H12V8.97534H18V22.9753ZM4 14.9753H16V16.9753H4V14.9753ZM4 10.9753H16V12.9753H4V10.9753ZM4 18.9753H16V20.9753H4V18.9753Z"
        fill={fill}
      />
    </svg>
  );
}

export default Reports;
